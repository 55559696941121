.add-post .filepond--root,
.add-post .filepond--root .filepond--drop-label {
    min-height: 400px;
    width: 100%;
    background-color: #dfe8fd;
}
.add-post .filepond--image-preview-overlay-success {
    color: rgb(54 94 151);
}

