.chat .filepond--root,
.chat .filepond--root .filepond--drop-label {
    /*min-height: 400px;*/
    /*width: 100%;*/
    background-color: #ffffff;
}
.chat .filepond--image-preview-overlay-success {
    color: rgb(54 94 151);
}

